import request from '@/utils/requestV2'
const qs = require('qs')

// 获取非标资源的尺寸、面积、MR套搞、效果图
export function getproductfixfile (data) {
  return request({
    url: '/ooh-product/v1/productfix/getproductfixfile',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取设备所属的主题媒介集合
export function getSkuList (data) {
  return request({
    url: '/ooh-product/v1/productfix/getskulist',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 设置主题媒介MR
export function setMR (data) {
  return request({
    url: '/ooh-product/v1/productfix/setmr',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取MR历史记录
export function getMRlist (data) {
  return request({
    url: '/ooh-product/v1/productfix/getmrlist',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 设置当前生效版本
export function changeMR (data) {
  return request({
    url: '/ooh-product/v1/productfix/changemr',
    method: 'POST',
    data: qs.stringify(data)
  })
}
