<template>
    <Modal v-model="isShow" width="1000" footer-hide>
        <h4 class="workplatform-title m-t-10 m-b-10">任务基本信息</h4>
        <Row>
            <i-col span="8">
                <Label class="title">编号</Label>
                <span class="text-orange">{{MR.resourceCode}}</span>
            </i-col>
            <i-col span="8">
                <Label class="title">资源类型</Label>
                <span>{{MR.resourcetypeName}}</span>
            </i-col>
            <i-col span="8">
                <Label class="title">线路</Label>
                <span>{{MR.assetName}}</span>
            </i-col>
            <i-col span="8" class="p-t-5">
                <Label class="title">站点</Label>
                <span>{{MR.domainName}}</span>
            </i-col>
            <!-- <i-col span="8" class="p-t-5">
                <Label class="title">站点</Label>
                <span>{{MR.domainName}}</span>
            </i-col> -->
            <i-col span="8" class="p-t-5">
                <Label class="title">作业日期</Label>
                <span>{{MR.actionDate}}</span>
            </i-col>
            <i-col span="8" class="p-t-5">
                <Label class="title">作业名称</Label>
                <span>{{MR.domainName}}</span>
            </i-col>
            <i-col span="8" class="p-t-5">
                <Label class="title">最后一次更新时间</Label>
                <span>{{MR.updateTime}}</span>
            </i-col>
            <i-col span="8" class="p-t-5">
                <Label class="title">备注</Label>
                <span>{{MR.remark}}</span>
            </i-col>
        </Row>

        <h4 class="workplatform-title m-t-10 m-b-10">设置设备关联的主题媒介</h4>
        <Row>
            <i-col span="24">
                <Select v-model="chooseProductSkuId"
                  size="small"
                  placeholder="主题媒介清单"
                  :clearable="true"
                  style="width:300px"
                >
                  <Option
                    v-for="item in themeList"
                    :key="item.productskuId"
                    :value="item.productskuId"
                  >{{item.productName}}-{{item.productskuName}}</Option>
                </Select>
                <Button
                  type="primary"
                  size="small"
                  class="m-l-10"
                  :disabled="!chooseProductSkuId || fileLocation===''"
                  @click="handleRelationTheme"
                >关联媒介</Button>

                <Button
                  v-if="svgHtml.length>0"
                  type="success"
                  size="small"
                  class="m-l-10"
                  @click="handleDownloadMR"
                >下载MR套稿</Button>
            </i-col>
        </Row>

        <h4 class="workplatform-title m-t-10 m-b-10">MR套稿</h4>
        <div v-if="svgHtml" v-html="svgHtml" class="svg-station-content " style="height:500px;" id="svg-station-content"></div>
        <div v-else class="default-content">
            <p style="font-size: xx-large;">抱歉，无法加载MR套稿！</p>
        </div>
    </Modal>
</template>

<script>
import axios from 'axios'
import svgPanZoom from 'svg-pan-zoom'
import { downloadFilePostRequest } from '@/utils/download'
import { HTMLEncode } from '@/utils'
import { getSkuList, setMR } from '@/api/product/productfix'

export default {
  data () {
    return ({
      isShow: false,
      spinShow: false,
      MR: {},
      svgHtml: '',
      showBindTheme: false,
      themeList: [],
      chooseProductSkuId: null,
      fileLocation: ''
    })
  },
  methods: {
    initPageData (data, bindTheme = false) {
      this.MR = data
      this.svgHtml = ''
      this.fileLocation = ''
      this.isShow = true
      this.showBindTheme = bindTheme
      if (bindTheme) {
        this.loadSkuList(data.deviceId)
      }

      if (data.taskitemAttachs.length > 0 && data.taskitemAttachs[0].mimeType === 'image/svg+xml') {
        for (let index = 0; index < data.taskitemAttachs.length; index++) {
          const element = data.taskitemAttachs[index]
          if (element.mimeType === 'image/svg+xml') {
            this.fileLocation = element.fileKey
            axios.get(element.fileKey).then(res => {
              this.svgHtml = res.data
              this.$nextTick(() => {
                const cuurentSvgElement = document.getElementById('svg-station-content').firstElementChild
                // 设置svg呈现在画板上的尺寸
                cuurentSvgElement.setAttribute('width', '100%')
                cuurentSvgElement.setAttribute('height', '100%')
                svgPanZoom(cuurentSvgElement, {
                  panEnabled: true,
                  controlIconsEnabled: false,
                  zoomEnabled: true,
                  dblClickZoomEnabled: true,
                  center: true
                }).zoom(1.3)
              })
            })
            break
          }
        }
      }
    },
    loadSkuList (deviceId) { // 加载设备所属的主题媒介集合
      getSkuList({ deviceId: deviceId, enabled: true }).then(res => {
        this.themeList = res
        this.chooseProductSkuId = res.length > 0 ? res[0].productskuId : null
      })
    },
    handleRelationTheme () {
      const postData = {
        fileLocation: this.fileLocation,
        skuId: this.chooseProductSkuId
      }

      setMR(postData).then(res => {
        this.$Notice.success({ desc: '关联主题媒介成功！' })
        this.isShow = false
      })
    },
    handleDownloadMR () {
      const fileName = 'MR套稿(' + this.MR.domainName + '_' + this.MR.resourceCode + ').png'
      const postData = {
        svg: HTMLEncode(this.svgHtml)
      }
      downloadFilePostRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-draw/svg/downloadsvgpng', postData, fileName)
    }
  }
}
</script>
